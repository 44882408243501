<template>
  <v-row>
    <v-col
      cols="12"
    >

      <base-card>
        <v-card-text>
          <v-card-title>
            Listado Emisores FE
            <v-spacer/>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              hide-details
              label="Buscar"
              single-line
            />
            <v-spacer/>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="detailsEmisor"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.Editar`]="{ item }">
              <v-dialog
                max-width="10000"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="info darken-3"
                    style="cursor:pointer;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-cellphone-check
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                      DATOS EMISOR -  [{{item.NombreCompania}}]

                      <v-icon
                        style="margin-left: 750px !important;"
                        @click="dialog.value = false"
                      >mdi-close
                      </v-icon>

                    </v-toolbar>
                    <campo-edit-emisor
                      :datosEmisor=item
                    />
                  </v-card>
                </template>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from 'vuex'

export default {
  components: {
    CampoEditEmisor: () => import('./CampoEditEmisor'),
  },
  name: 'ShowEmisor',
  data() {
    return {
      search: '',
      alertStatus: false,
      headers: [
        {text: 'Compania', value: 'NombreCompania'},
        {text: 'Nit', value: 'Nit'},
        {text: 'Tipo Identificacion', value: 'NombreTipoIdentificacion'},
        {text: 'Tipo Persona', value: 'NombreTipoPersona'},
        {text: 'Nombre Comercial', value: 'NombreComercial'},
        {text: 'Razon Social', value: 'RazonSocial'},
        {text: 'Tributo', value: 'NombreTributo'},
        {text: 'Obligaciones', value: 'Obligaciones'},
        {text: 'Nombre Contacto', value: 'NombreContacto'},
        {text: 'Email Contacto', value: 'EmailContacto'},
        {text: 'Telefono Contacto', value: 'TelefonoContacto'},
        {text: 'Pais', value: 'Pais'},
        {text: 'Departamento', value: 'NombreDepartamento'},
        {text: 'Ciudad', value: 'NombreCiudad'},
        {text: 'Direccion', value: 'Direccion'},
        {text: 'Editar', value: 'Editar'},
      ],
    }
  },
  computed: {
    detailsEmisor() {
      return this.$store.state.emisor.listEmisores.Mensaje
    },
    updateResponseClient() {
      return this.$store.state.clients.updateStatusResponse
    },

  },
  watch: {
    updateResponseClient() {
      if (this.updateResponseClient > 0) {
        this.generateAlertStatus()
        this.$store.dispatch('clients/setUpdateResponseClient', 0)
      }
    },
  },
  created() {
    this.getListEmisor()
  },
  methods: {
    generateAlertStatus() {
      this.alertStatus = true
      this.$store.dispatch('clients/getListClients')
      setTimeout(() => {
        this.alertStatus = false
      }, 3000)
    },
    async getListEmisor() {
      await this.$store.dispatch('emisor/getListEmisor')
    },
    async activeInactiveClients(item) {
      await this.$store.dispatch('clients/activeInactiveClient', item)
    },

  },
}
</script>

<style scoped>

</style>
